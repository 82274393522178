import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {environment} from 'src/environments/environment';

@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['privacy.page.scss']
})
export class PrivacyPage {
	static url = 'privacy';
	checkIfVenueExistPrivacy = '';
	constructor(
		private repository: RepositoryService,
		private router: Router
	) {}

	static async navigate(router: Router) {
		await router.navigateByUrl(PrivacyPage.url);
	}
	ionViewDidEnter() {
		if (this.repository._venue && this.repository._order) {
			if (this.repository._venue && this.repository._venue?.legal?.privacy) {
				this.checkIfVenueExistPrivacy = this.repository._venue?.legal?.privacy;
			} else {
				this.checkIfVenueExistPrivacy = '';
			}
		} else {
			this.checkIfVenueExistPrivacy = '';
		}
		this.privacyLogic();
	}
	async privacyLogic() {
		const response = (await Api.getLegal(environment.venueId)).data;
		if (response.privacy) {
			this.checkIfVenueExistPrivacy = response.privacy;
		}
	}
	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
