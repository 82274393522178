import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {environment} from 'src/environments/environment';
import Localization from '../../../smoothr-web-app-core/models/Localization';

@Component({
	selector: 'app-short-modal',
	templateUrl: './info-modal.component.html',
	styleUrls: ['info-modal.component.scss']
})
export class InfoModalComponent {
	title: string;
	info: string;
	environment = environment;

	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, data: {title: string; info: string}) {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: InfoModalComponent,
			componentProps: {
				title: data.title,
				info: data.info
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
