import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {ActivatedRoute, Router} from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {sleep, venueAcceptsOrders} from '../../../smoothr-web-app-core/utils/utils';
import {MenuPage} from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SignInPage} from '../sign-in/sign-in.page';
import {Api} from 'src/smoothr-web-app-core/api/api';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.table_dot.html',
	styleUrls: ['./home.page.table_dot.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanUrl = 'scan-qr/:venueId';
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	activeVenue: Venue = null;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		public translate: TranslateService,
		private route: ActivatedRoute,
		private modalCtrl: ModalController,
		private snackBar: MatSnackBar
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		await router.navigateByUrl(HomePage.url);
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.locateAndShowStores(PreorderType.INSIDE);
		this.route.paramMap.subscribe(params => {
			if (params && params.get('venueId')) {
				this.loadVenueByQrCode(params.get('venueId'));
			}
		});
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores(preorderType: PreorderType) {
		this.loading = true;

		const venue = (await this.repository.getAllVenues()).find(ven => venueAcceptsOrders(ven, preorderType));

		if (!venue) {
			console.log('No available venues');
			this.loading = false;
			return;
		}
		this.activeVenue = venue;
	}
	async selectVenue(venue: Venue) {
		await this.loadVenueAndCreateOrder(venue, null, PreorderType.INSIDE);
	}
	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		console.log('loadVenueAndCreateOrder(', venue, address, preorderType, ')');
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType);
		await MenuPage.navigate(this.router);
		this.loading = false;
	}

	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async loadVenueByQrCode(venueId: string) {
		this.loading = true;
		if (!venueId) {
			return;
		}
		try {
			const venueData = await this.repository.getVenue(venueId);
			const selectTable = await SelectTableModalComponent.show(this.modalCtrl, venueData, this.order);
			if (venueData && selectTable) {
				this.repository.createOrder(venueData, null, PreorderType.INSIDE);
				sleep(100);
				this.order.tableNumber = selectTable.table;
				this.order.table = selectTable.tableId;
				this.repository.order.emit(this.order);
				sleep(200);
				await MenuPage.navigate(this.router);
				MenuPage.navigate(this.router);
			}
			this.loading = false;
		} catch (e) {
			console.log(e);
			this.snackBar.open(this.translate.instant('home_page.scan_table_error'), null, {
				duration: 2000
			});
			this.loading = false;
		}
	}

	async goToSignIn() {
		SignInPage.navigate(this.router);
		// just test logic for sending push notification
		// try {
		// 	const userDevice = JSON.parse(localStorage.getItem('deviceData'));
		// 	console.log(userDevice);
		// 	// alert(userDevice)
		// 	if (!userDevice) {
		// 		return;
		// 	}
		// 	const result = await Api.sendTestNotification({
		// 		device: userDevice._id,
		// 		content: 'Message',
		// 		subject: 'App'
		// 	});
		// 	// alert(result)
		// } catch (e) {
		// 	alert(e);
		// 	console.log(e);
		// }
	}
}
