import {Component, ViewChild} from '@angular/core';
import {IonSlides, ModalController} from '@ionic/angular';
import {environment} from 'src/environments/environment';
import Localization from '../../../smoothr-web-app-core/models/Localization';

@Component({
	selector: 'app-slider-info-modal',
	templateUrl: './slider-info-modal.component.html',
	styleUrls: ['slider-info-modal.component.scss']
})
export class SliderInfoModalComponent {
	environment = environment;

	slideItems = [1, 2, 3, 4, 5];

	@ViewChild(IonSlides, {static: false}) slides: IonSlides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: true
	};

	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			cssClass: 'slider-info-modal',
			component: SliderInfoModalComponent,
			componentProps: {},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	async next() {
		if (await this.slides.isEnd()) {
			this.dismiss();
		}
		await this.slides.update();
		await this.slides.slideNext();
	}
}
