import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const table_dotCustomerGroup = 'table_dot';
export const customerGroup = table_dotCustomerGroup;

const supportEmail = {
	table_dot: 'olearys@smoothr.de'
};
const firebaseConfig = {
	table_dot: {
		apiKey: 'AIzaSyCmkJ5LhAwNaEyikSocOwcKi8aQ1kNBweE',
		authDomain: 'tabledot-4f377.firebaseapp.com',
		projectId: 'tabledot-4f377',
		storageBucket: 'tabledot-4f377.appspot.com',
		messagingSenderId: '702268553377',
		appId: '1:702268553377:web:011855140eb8ef8990d8e0',
		measurementId: 'G-Q3GW3ETXG8',
		vapidKey: 'BEF2F2yB8F3F0hy9ESx2_4FZrr5i8DEE8kdC7iBTCr8BnMU1RXunnVTRqok9x6nuwhgKbNBO3nHsG4z_8kmmfY4'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyASyhGnKtZa_Id2l-Gp9_vWIDDIIvlK2OE',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	hasRecommendations: false,
	venueId: '65d47f64bb0b77d5c4a5f442',
	payPalValidatePrice: true,
	payPalMinPrice: 20,
	showDiffrenceModal: false,
	showOrderPriceFromBackend: false
};

import 'zone.js/dist/zone-error';
