import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {environment} from '../../../environments/environment';
import {Api} from 'src/smoothr-web-app-core/api/api';

@Component({
	selector: 'app-impressum',
	templateUrl: './impressum.page.html',
	styleUrls: ['impressum.page.scss']
})
export class ImpressumPage {
	static url = 'impressum';
	environment = environment;
	checkIfVenueExistImpressum: string;
	constructor(
		private repository: RepositoryService,
		private router: Router
	) {}

	static navigate(router: Router) {
		router.navigateByUrl(ImpressumPage.url);
	}
	ionViewDidEnter() {
		console.log(this.repository._venue?.legal?.imprint);
		if (this.repository._venue && this.repository._order) {
			if (this.repository._venue && this.repository._venue?.legal?.imprint) {
				this.checkIfVenueExistImpressum = this.repository._venue?.legal?.imprint;
			} else {
				this.checkIfVenueExistImpressum = '';
			}
		} else {
			this.checkIfVenueExistImpressum = '';
		}
		this.privacyLogic();
	}
	async privacyLogic() {
		const response = (await Api.getLegal(environment.venueId)).data;
		if (response.privacy) {
			this.checkIfVenueExistImpressum = response.imprint;
		}
	}
	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
