import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {environment} from '../../../environments/environment';
import {Api} from 'src/smoothr-web-app-core/api/api';

@Component({
	selector: 'app-tos',
	templateUrl: './tos.page.html',
	styleUrls: ['tos.page.scss']
})
export class TosPage {
	static url = 'tos';
	environment = environment;
	checkIfVenueExistTos = '';
	constructor(
		private repository: RepositoryService,
		private router: Router
	) {}

	static async navigate(router: Router) {
		await router.navigateByUrl(TosPage.url);
	}
	ionViewDidEnter() {
		if (this.repository._venue && this.repository._order) {
			if (this.repository._venue && this.repository._venue?.legal?.terms) {
				this.checkIfVenueExistTos = this.repository._venue?.legal?.terms;
			} else {
				this.checkIfVenueExistTos = '';
			}
		} else {
			this.checkIfVenueExistTos = '';
		}
		this.privacyLogic();
	}
	async privacyLogic() {
		const response = (await Api.getLegal(environment.venueId)).data;
		if (response.privacy) {
			this.checkIfVenueExistTos = response.terms;
		}
	}
	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
